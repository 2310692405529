import React from 'react'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'

import { auth } from '../../firebase'

const withAuthentication = Component => {
  @inject('sessionStore')
  class WithAuthentication extends React.Component {
    static propTypes = {
      sessionStore: PropTypes.object.isRequired
    }

    componentDidMount() {
      const { sessionStore } = this.props

      auth.onAuthStateChanged(user => {
        sessionStore.setAuthUser(user ? user : null)
      })
    }

    render() {
      return <Component />
    }
  }
  return WithAuthentication
}

export default withAuthentication
