import React from 'react'
import ReactDOM from 'react-dom'
import './i18n'

import App from './App'

ReactDOM.render(<App />, document.getElementById('root'))

// import registerServiceWorker from './registerServiceWorker'
// registerServiceWorker()
