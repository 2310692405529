import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, reaction } from 'mobx'
import { observer, inject } from 'mobx-react'
import { Heading, Text as BaseText, Box } from 'rebass'
import { SideBySideLayout, PrimaryButton } from '@bonobolabs/mds-web-ui'
import * as Sentry from '@sentry/react'

// Sentry
import setupErrorTracking from './errorTracking'
setupErrorTracking()

// https://docs.sentry.io/clients/javascript/integrations/react/

const Text = props => (
  <BaseText
    mb={4}
    lineHeight={1.5}
    fontSize={[2, 3]}
    color="grayapptitle"
    {...props}
  />
)

const ErrorFallbackPage = () => (
  <SideBySideLayout useRouterLink={false}>
    <Box css={{ textAlign: 'center' }}>
      <Heading mt={4} mb={4} color="grayapptitle" fontSize={[4, 5]}>
        Sorry, something went wrong
      </Heading>
      <Text>
        Our team has been notified, we&apos;ll fix it as soon as we can!
      </Text>
      <PrimaryButton mt={4} is="a" href="/" uppercase>
        Go back and try again
      </PrimaryButton>
    </Box>
  </SideBySideLayout>
)

@inject('sessionStore')
@observer
export class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    sessionStore: PropTypes.object.isRequired
  }

  @observable
  error = null

  constructor(props) {
    super(props)
    this.setupUserContext()
  }

  setupUserContext() {
    const sessionStore = this.props.sessionStore

    reaction(
      () => sessionStore.authUser,
      () => sessionStore.authUser && this.setContext(sessionStore.authUser)
    )
  }

  setContext(user) {
    const context = {
      email: user.email,
      id: user.uid,
      name: user.displayName
    }
    Sentry.setUser(context)
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback={<ErrorFallbackPage />}>
        {this.props.children}
      </Sentry.ErrorBoundary>
    )
  }
}
