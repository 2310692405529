import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'

import { firebaseConfig as config } from './env'

firebase.initializeApp(config)

const firestore = firebase.firestore()
const auth = firebase.auth()

export { auth, firestore }

export const updateName = name => {
  return auth.currentUser.updateProfile({ displayName: name })
}

export const updateEmail = email => auth.currentUser.updateEmail(email)

export const deleteAccount = () => auth.currentUser.delete()

export const linkWithApple = () => {
  const apple = new firebase.auth.OAuthProvider('apple.com')
  return auth.currentUser.linkWithPopup(apple)
}

export const sendEmailVerification = () =>
  auth.currentUser.sendEmailVerification()

export const verifyEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

// TODO move error handling here from PasswordField?

export const changePassword = (user, newPassword) =>
  user.updatePassword(newPassword)

export const getStripePortalURL = firebase
  .functions()
  .httpsCallable('stripe_getPortalURL')

// FUNCTIONS TO REPLACE STRIPE CHECKOUT PROCESS
// 1. validates coupon code
export const validateTrialCoupon = firebase
  .functions()
  .httpsCallable('trialCoupon_validate')

// 2. redeems 3-month trial from coupon
export const redeemTrialCoupon = firebase
  .functions()
  .httpsCallable('trialCoupon_redeem')

//////////////////////////////////////////////

export const listenToMembership = (uid, appName, onSnapshot) => {
  try {
    firestore
      .collection('memberships')
      .doc(`${appName}_${uid}`)
      .onSnapshot(onSnapshot)
  } catch (error) {
    console.error('Error during membership fetch')
    console.error(error)
  }
}

export const providerIds = {
  google: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  facebook: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  password: firebase.auth.EmailAuthProvider.PROVIDER_ID,
  apple: 'apple.com'
}
export const providerNames = {
  [firebase.auth.GoogleAuthProvider.PROVIDER_ID]: 'Sign in with Google',
  [firebase.auth.FacebookAuthProvider.PROVIDER_ID]: 'Sign in with Facebook',
  [firebase.auth.EmailAuthProvider.PROVIDER_ID]: 'Email & password',
  'apple.com': 'Sign in with Apple'
}
export const unlinkPasswordProvider = () => {
  return auth.currentUser.unlink(providerIds.password)
}
export const unlinkProvider = providerId => {
  return auth.currentUser.unlink(providerId)
}
