import React from 'react'
import Title from './title'
import { SideBySideLayout, Heading } from '@bonobolabs/mds-web-ui'
import { useTranslation } from 'react-i18next'

const Missing = () => {
  const { t } = useTranslation()

  return (
    <Title page="404">
      <SideBySideLayout>
        <Heading mt={4}>{t('main.nothingHere')}</Heading>
      </SideBySideLayout>
    </Title>
  )
}
export default Missing
