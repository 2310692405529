import { observable } from 'mobx'
import PreferenceModel from '../models/PreferenceModel'
import * as Utils from '../utils'

export default class PreferenceStore {
  @observable
  preferences = []

  // @computed get completedCount() {
  //     return this.todos.length - this.activeTodoCount
  // }

  // subscribeServerToStore() {
  //     reaction(
  //         () => this.toJS(),
  //         todos => window.fetch && fetch('/api/todos', {
  //             method: 'post',
  //             body: JSON.stringify({ todos }),
  //             headers: new Headers({ 'Content-Type': 'application/json' })
  //         })
  //     )
  // }

  addPref(title, value) {
    this.preferences.push(new PreferenceModel(this, Utils.uuid(), title, value))
  }

  toJS() {
    return this.preferences.map(todo => todo.toJS())
  }

  static fromJS(array) {
    const prefStore = new PreferenceStore()
    prefStore.preferences = array.map(item =>
      PreferenceModel.fromJS(prefStore, item)
    )
    return prefStore
  }
}
