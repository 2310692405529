import { observable, action, computed } from 'mobx'
import { auth, providerIds } from '../firebase'

import AppMembershipModel from '../models/AppModel'

export default class SessionStore {
  @observable
  authUser = null
  @observable
  loading = true

  @observable
  name = null
  @observable
  email = null
  @observable
  loginMessage = null

  @observable
  authToken = null
  @observable
  cannyToken = null

  @observable
  timepageModel = null
  @observable
  actionsModel = null
  @observable
  flowModel = null

  @observable
  hasPassword = null

  @observable
  providers = []

  // Called from compontents/auth/withAuthentication on Firebase callback
  // If auth state loads and no user in then this will be called with `null`
  // Uses an @action so that it'll get wrapped in a transaction and not update
  // in parts - so there's no race condition around authUser/loading updating
  @action
  setAuthUser = user => {
    this.authUser = user
    this.loading = false

    this.name = this.getName()
    this.email = this.getEmail()

    this.getToken()

    if (user) {
      this.timepageModel = new AppMembershipModel('Timepage', user.uid)
      this.actionsModel = new AppMembershipModel('Actions', user.uid)
      this.flowModel = new AppMembershipModel('Flow', user.uid)

      this.providers = this.authUser.providerData || []
      this.hasPassword = this.hasProvider('password')
    } else {
      this.providers = []
      this.hasPassword = null
    }
  }

  getName() {
    return this.authUser && this.authUser.displayName
  }

  getEmail() {
    return this.authUser && this.authUser.email
  }

  toData() {
    return { name: this.name, email: this.email }
  }

  @action
  refreshAuthUser() {
    auth.currentUser.reload()
    this.setAuthUser(auth.currentUser)
  }

  forceReauth(message) {
    auth.signOut()
    this.loginMessage = message
  }

  getToken = () => {
    this.authUser &&
      this.authUser.getIdToken().then(t => {
        this.authToken = t
        this.getCannyToken()
      })
  }

  getCannyToken = () => {
    const baseURL = '/api/functions/'
    const cannyURL = `${baseURL}cannySSOToken`

    fetch(cannyURL, {
      method: 'post',
      body: JSON.stringify({ idToken: this.authToken }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      redirect: 'error'
    })
      .then(response => response.json())
      .then(json => (this.cannyToken = json.token))
      .catch(
        e =>
          // Fall back to standard Canny auth
          // if we don't set this the feedback widgets load forever
          (this.cannyToken = '')
      )
  }

  hasProvider(providerName) {
    const providerId = providerIds[providerName]
    const providerData = this.providers
    return providerData.some(p => p.providerId === providerId)
  }

  @computed
  get hasAnActiveStripeMembership() {
    const memberships = [this.timepageModel, this.actionsModel, this.flowModel]
    return memberships
      .filter(m => m)
      .some(
        m =>
          m.membershipSource === 'stripe' && !m.membershipExpired && !m.loading
      )
  }

  @computed
  get hasAnActiveMembership() {
    const memberships = [this.timepageModel, this.actionsModel, this.flowModel]
    return memberships
      .filter(m => m)
      .some(
        m =>
          m.membershipType !== null &&
          m.membershipType !== 'trial' &&
          !m.membershipExpired &&
          !m.loading
      )
  }
}
