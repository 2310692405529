import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import moment from 'moment'
import getEnv from './env'

const environment = getEnv()

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    // lng: 'ko',
    // lng: 'en',
    fallbackLng: 'en',

    debug: environment !== 'production',

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if (moment.isMoment(value)) return value.format(format)
        return value
      }
    },

    backend: {
      loadPath: '/locales/{{lng}}.json'
    },

    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br']
  })

i18n.on('languageChanged', lng => {
  moment.locale(lng)
})

export default i18n
