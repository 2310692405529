import React, { Component, Fragment, lazy } from 'react'
import { Route as BaseRoute, Redirect, Switch } from 'react-router-dom'
import * as Sentry from '@sentry/react'

// Logic
import Missing from './Missing'

import withAuthentication from './auth/withAuthentication'
import withAuthorization from './auth/withAuthorization'

import { createGlobalStyle } from 'styled-components'

const Account = lazy(() =>
  import(/* webpackChunkName: "account" */ './Account')
)
const Profile = lazy(() =>
  import(/* webpackChunkName: "profile" */ './Profile')
)
const Success = lazy(() =>
  import(/* webpackChunkName: "success" */ './Success')
)
const Redeem = lazy(() => import(/* webpackChunkName: "redeem" */ './Redeem'))
const Login = lazy(() => import(/* webpackChunkName: "login" */ './Login'))
const Logout = lazy(() => import(/* webpackChunkName: "logout" */ './Logout'))
const Privacy = lazy(() =>
  import(/* webpackChunkName: "privacy" */ './Privacy')
)
const ActionsFeedback = lazy(() =>
  import(/* webpackChunkName: "actions-feedback" */ './Feedback/Actions')
)
const TimepageFeedback = lazy(() =>
  import(/* webpackChunkName: "timepage-feedback" */ './Feedback/Timepage')
)
const FlowFeedback = lazy(() =>
  import(/* webpackChunkName: "flow-feedback" */ './Feedback/Timepage')
)

export const GlobalStyles = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-size-adjust: 100%;
  }
  body {
    margin: 0;
  }
`

const Route = Sentry.withSentryRouting(BaseRoute)

const loggedIn = user => !!user

@withAuthentication
export default class Main extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/" component={IndexRoute} />
          <Route exact path="/account" component={Account} />
          <Route path="/redeem/:coupon" component={Redeem} />
          <Route path="/redeem" component={Redeem} />
          <Route path="/success" component={Success} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          {/* <Route exact path="/error" render={() => this.error()} /> */}
          <Route exact path="/privacy" component={Privacy} />
          <Route
            exact
            path="/feedback"
            render={() => <Redirect to="/feedback/actions" />}
          />
          <Route path="/feedback/actions" component={ActionsFeedback} />
          <Route path="/feedback/timepage" component={TimepageFeedback} />
          <Route path="/feedback/flow" component={FlowFeedback} />
          <Route component={Missing} />
        </Switch>
        <GlobalStyles />
      </Fragment>
    )
  }
}

@withAuthorization(loggedIn)
class IndexRoute extends Component {
  render() {
    return <Redirect to="/profile" />
  }
}
