import { observable } from 'mobx'

export default class PreferenceModel {
  key
  @observable
  title
  @observable
  description
  @observable
  value

  constructor(key, title, description, value) {
    this.key = key
    this.title = title
    this.description = description
    this.value = value
  }

  setValue(value) {
    this.value = value
  }

  toJS() {
    return {
      key: this.key,
      title: this.title,
      description: this.description,
      value: this.value
    }
  }
}
