import runtimeEnv from '@mars/heroku-js-runtime-env'
import getEnv from '../env'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const env = runtimeEnv()
const SENTRY_URL = env.REACT_APP_RAVEN_URL

const getTags = () => {
  // Get env variables from Heroku
  // https://devcenter.heroku.com/articles/dyno-metadata
  const keys = Object.keys(env).filter(k => k.startsWith('REACT_APP_HEROKU'))
  const heroku_env = keys.reduce(
    (obj, key) => ({ ...obj, [key.replace('REACT_APP_', '')]: env[key] }),
    {}
  )
  return heroku_env
}

const getConfig = () => {
  const release = env.REACT_APP_HEROKU_RELEASE_VERSION || 'development'
  const serverName = env.REACT_APP_HEROKU_APP_NAME || 'local'

  const environment = getEnv()

  // https://docs.sentry.io/clients/javascript/config/
  return {
    release: release,
    environment: environment,
    serverName: serverName,
    tags: {
      ...getTags()
    }
  }
}

const setupErrorTracking = () => {
  const config = getConfig()

  if (SENTRY_URL) {
    Sentry.init({
      dsn: SENTRY_URL,
      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({ history })
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,
      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: [/^\//, /\.cloudfunctions\.net/],

      release: config.release,
      environment: config.environment,
      initialScope: { tags: config.tags }
    })
  }
}

export default setupErrorTracking
