import React, { Component, Suspense } from 'react'
import { Provider as StoreProvider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { Router } from 'react-router'
import { ThemeProvider, LoadingPage } from '@bonobolabs/mds-web-ui'
import { createBrowserHistory } from 'history'
import { ErrorBoundary } from './error/ErrorBoundary'

import PreferenceStore from './stores/PreferenceStore'
import ViewStore from './stores/ViewStore'
import SessionStore from './stores/SessionStore'

import Main from './components/Main'
import getEnv from './env'

const prefStore = new PreferenceStore()
const viewStore = new ViewStore()
const sessionStore = new SessionStore()

// Router
const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()
const history = syncHistoryWithStore(browserHistory, routingStore)

const stores = { prefStore, viewStore, routing: routingStore, sessionStore }

export default class App extends Component {
  componentDidMount() {
    const environment = getEnv()
    console.log(`Environment: ${environment}`)
  }
  render() {
    return (
      <ThemeProvider>
        <StoreProvider {...stores}>
          <ErrorBoundary>
            <Router history={history}>
              <Suspense fallback={<LoadingPage />}>
                <Main />
              </Suspense>
            </Router>
          </ErrorBoundary>
        </StoreProvider>
      </ThemeProvider>
    )
  }
}
