import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import { LoadingPage } from '@bonobolabs/mds-web-ui'

const withAuthorization = condition => Component => {
  @inject('sessionStore')
  @observer
  class WithAuthentication extends React.Component {
    static propTypes = {
      sessionStore: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired
    }

    render() {
      const { sessionStore, location } = this.props
      const hasAuthorization = condition(sessionStore.authUser)
      const loading = sessionStore.loading

      return loading ? (
        <LoadingPage />
      ) : hasAuthorization ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      )
    }
  }
  return WithAuthentication
}

export default withAuthorization
